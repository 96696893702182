<script>
	import { Icon } from '@ctm/icons';
	import { Report } from '@ctm/icons/data';
	import { ExternalLink } from '@ctm/icons/interface';
	import { base } from '$app/paths';
	import Flex from '$lib/Flex';
	import Spacer from '$lib/Spacer';
	import Stack from '$lib/Stack';
	import Text from '$lib/Text';
	import addClassToMarkup from '$lib/utils/addClassToMarkup.js';
	import styles from './AppTile.module.css';

	/** @type {IconData | string} */
	export let icon;

	/** @type {string} */
	export let href;

	/** @type {string} */
	export let label;

	/** @type {string} */
	export let description;

	/** @type {(event: HTMLEvent<MouseEvent, HTMLAnchorElement>) => void} */
	export let onclick = () => {};

	let iconFailed = false;
	function handleIconError() {
		iconFailed = true;
	}

	$: external = !href.startsWith('/');
</script>

<a class={styles['app-tile']} href={external ? href : base + href} target={external ? '_blank' : undefined} {...$$restProps} on:click={onclick}>
	{#if typeof icon === 'string' && !iconFailed}
		{#if icon.startsWith('<svg')}
			<!-- TODO: should we handle this in the @ctm/icons <Icon> component? -->
			<!-- eslint-disable svelte/no-at-html-tags -->
			{@html addClassToMarkup(icon, styles.icon)}
		{:else}
			<img class={styles.icon} alt="{label} icon" src={icon} on:error={handleIconError} />
		{/if}
	{:else}
		<Icon class={styles.icon} icon={!icon || iconFailed ? Report : icon} />
	{/if}
	<Stack grow>
		<Flex gap="xs" valign="top">
			<Text ellipsis size="lg" weight="semibold">{label}</Text>
			<Spacer />
			{#if external}
				<Icon class={styles['external-icon']} icon={ExternalLink} size="sm" />
			{/if}
		</Flex>
		<Text ellipsis size="xs">{description}</Text>
	</Stack>
</a>
