const classRegex = /<svg[^>]*class="([^"]*)"/di;
/**
 * @param {string} markup
 * @param {string} className
 * @returns {string}
 */
export default function addClassToMarkup(markup, className) {
	const match = classRegex.exec(markup);
	if (match && match.indices) {
		const [start, end] = match.indices[1];
		markup = markup.slice(0, start) + className + (start < end ? ' ' : '') + markup.slice(start);
	} else {
		markup = markup.slice(0, 4) + ` class="${className}"` + markup.slice(4);
	}
	return markup;
}

if (import.meta.vitest) {
	const { describe, expect, test } = import.meta.vitest;

	/** @type {Array<{ name: string, input: Parameters<addClassToMarkup>, output: ReturnType<addClassToMarkup> }>} */
	const testCases = [
		{ name: 'element without class attribute', input: ['<svg></svg>', 'test'], output: '<svg class="test"></svg>' },
		{ name: 'element with existing class attribute', input: ['<svg class="abc"></svg>', 'test'], output: '<svg class="test abc"></svg>' },
	];

	describe('addClassToMarkup()', () => {
		for (const testCase of testCases) {
			test(testCase.name, () => {
				expect(addClassToMarkup.apply(null, testCase.input)).toEqual(testCase.output);
			});
		}
	});
}
